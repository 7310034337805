<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script lang="ts">
import iFrameLayout from "@/components/iframe-layout.vue"
import BandeauDemo from "@/components/bandeau-demo.vue"
import context from "@/context/index.js"
import { persistDataOnSessionStorage, useStore } from "@/stores/index.js"
import storageService from "@/lib/storage-service.js"

const { BaseLayout, MesAidesLayout } = context

export default {
  name: "App",
  components: {
    BaseLayout,
    iFrameLayout,
  },
  setup() {
    const store = useStore()
    store.$onAction(persistDataOnSessionStorage)
    store.initialize()
    store.setOpenFiscaParameters()
    return {
      store,
    }
  },
  computed: {
    layout: function () {
      return "iFrameLayout"
    },
  },
  mounted() {
    const params = new URLSearchParams(document.location.search.substring(1))
    if (params.has("iframe")) {
      this.store.setIframeOrigin(null)
    }

    const savedTheme = storageService.local.getItem("theme")
    if (savedTheme) {
      params.set("theme", savedTheme)
    }

    if (params.has("theme")) {
      this.$theme.update(params.get("theme"))
    }

    // this token is sent when generating the iframe 1jeune1solution,
    // it contains a hashed version of the end user ID,
    // so that we know who is browsing the iframe (as there is no Rosaly cookie within the iframe);
    //
    // when posting the iframe results to Rosaly, we know who is sending the result,
    // and so we can associate the results with the end user
    if (params.has("rosalyToken")) {
      storageService.local.setItem("rosalyToken", params.get("rosalyToken"));
    }

    if (this.store.iframeOrigin) {
      this.store.setIframeOrigin(null)
    }
  },
}
</script>
