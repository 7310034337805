export enum ThemeType {
  default = "default-dsfr",
  lightBlue = "light-blue",
  bordeauxMetropole = "bordeaux-metropole",
  rosaly = "rosaly",
  rosaly2024 = "rosaly2024",
}

export enum ThemeTypeLabel {
  default = "Thème bleu foncé (DSFR)",
  lightBlue = "Thème bleu clair",
  bordeauxMetropole = "Thème Bordeaux Métropole",
  rosaly = "rosaly",
  rosaly2024 = "Rosaly 2024",
}
