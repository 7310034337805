<template>
  <main role="main" class="aj-iframe-content">
    <slot />
  </main>
</template>

<script lang="ts">
import { useStore } from "@/stores/index.js"
export default {
  name: "IFrameLayout",
  setup() {
    return {
      store: useStore(),
    }
  },
  computed: {
    headerCollapse: function () {
      return this.store.iframeHeaderCollapse
    },
  },
}
</script>
