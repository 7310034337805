import { createApp, h } from "vue"
import "@gouvfr/dsfr/dist/dsfr.min.css"
import "@gouvfr/dsfr/dist/utility/utility.min.css"
import "@gouvfr/dsfr/dist/dsfr.module.min.js"
import App from "./app.vue"

import router from "./router.js"

import StateService from "./plugins/state-service.js"
import ThemeService from "./plugins/theme-service.js"

import * as Sentry from "@sentry/vue"
import VueCookies from "vue-cookies"

import "@/styles/aides-jeunes.css"

import MailDirective from "./directives/mail.js"
import SelectOnClickDirective from "./directives/select-on-click.js"

// @ts-ignore
import { iframeResizerContentWindow } from "iframe-resizer"

import "dayjs/locale/fr.js"
import customParseFormat from "dayjs/plugin/customParseFormat"
import dayjs from "dayjs"
import { createPinia } from "pinia"
import "@fontsource/signika"
import "@fontsource/roboto"

const Resizer = {
  install: function () {
    iframeResizerContentWindow
  },
}
const pinia = createPinia()

const app = createApp({
  render: () => h(App),
})

app.directive("mail", MailDirective)
app.directive("selectOnClick", SelectOnClickDirective)

app.use(Resizer)
app.use(StateService)
app.use(ThemeService)
app.use(VueCookies)

app.config.globalProperties.$filters = {
  capitalize(value: string = "") {
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
}
declare module "vue" {
  export interface ComponentCustomProperties {
    $theme: {
      current: string
      options: {
        title: string
        label: string
        value: string
      }[]
      update(string): void
    }
    $filters: {
      capitalize(string): string
    }
  }
}

dayjs.locale("fr")
dayjs.extend(customParseFormat)

app.use(pinia)
app.use(router)
app.mount(document.body)
