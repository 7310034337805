import Home from "@/views/home.vue"
import BaseLayout from "@/components/base-layout.vue"
import Simulation from "@/views/simulation.vue"

const name = process.env.VITE_CONTEXT_NAME

const routes = [
  {
    path: "/iframe",
    name: "iframe",
    component: () => import("@/views/iframe.vue"),
  },
]

export default {
  Home,
  BaseLayout,
  Simulation,
  name,
  routes,
}
