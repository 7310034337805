<template>
  <div class="aj-page--full-height">
    <main
      id="main"
      ref="main"
      role="main"
      class="fr-container fr-container--fluid aj-main-container"
      tabindex="-1"
    >
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
import Header1J1S from "@/components/1j1s/header-1j1s.vue"
import Footer1J1S from "@/components/1j1s/footer-1j1s.vue"
import Navigation from "@/components/1j1s/navigation-1j1s.vue"
import layoutRouteMixin from "@/mixins/layout-route-mixin.js"

export default {
  name: "BaseLayout",
  mixins: [layoutRouteMixin],
}
</script>
